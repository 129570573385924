import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  backdrop: {
    status: "false",
    message: "", // Default message is an empty string
  },
};

const backdropSlice = createSlice({
  name: "backdrop",
  initialState,
  reducers: {
    setBackdrop: (state, action) => {
      try {
        state.backdrop.status = action.payload.status;
        state.backdrop.message = action.payload.message || ""; // Use an empty string if message is not provided
      } catch (e) {
        alert("Failed");
      }
    },
  },
});

export const { setBackdrop } = backdropSlice.actions;
export default backdropSlice.reducer;

export const getBackdrop = (state: any) => state.backdrop.backdrop;

