import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sliders: {
    bridge_overpass: 2.2,
    bridge_underpass: 2.5,
    bridge_overwater: 3,
    bridge_overland: 2.3,
    tunnel: 5.5,
    rail: 6,
  },
  visibility: {
    bridge_overpass: true,
    bridge_underpass: true,
    bridge_overwater: true,
    bridge_overland: true,
    tunnel: true,
    rail: true,
  },
};

const sliderSlice = createSlice({
  name: "sliders",
  initialState,
  reducers: {
    // Set slider values
    setBridgeOverpassSlider: (state, action) => {
      state.sliders.bridge_overpass = action.payload;
    },
    setBridgeUnderPassSlider: (state, action) => {
      state.sliders.bridge_underpass = action.payload;
    },
    setBridgeOverWaterSlider: (state, action) => {
      state.sliders.bridge_overwater = action.payload;
    },
    setBridgeOverLandSlider: (state, action) => {
      state.sliders.bridge_overland = action.payload;
    },
    setTunnelSlider: (state, action) => {
      state.sliders.tunnel = action.payload;
    },
    setRailSlider: (state, action) => {
      state.sliders.rail = action.payload;
    },

    // Toggle visibility
    toggleBridgeOverpassVisibility: (state) => {
      state.visibility.bridge_overpass = !state.visibility.bridge_overpass;
    },
    toggleBridgeUnderPassVisibility: (state) => {
      state.visibility.bridge_underpass = !state.visibility.bridge_underpass;
    },
    toggleBridgeOverWaterVisibility: (state) => {
      state.visibility.bridge_overwater = !state.visibility.bridge_overwater;
    },
    toggleBridgeOverLandVisibility: (state) => {
      state.visibility.bridge_overland = !state.visibility.bridge_overland;
    },
    toggleTunnelVisibility: (state) => {
      state.visibility.tunnel = !state.visibility.tunnel;
    },
    toggleRailVisibility: (state) => {
      state.visibility.rail = !state.visibility.rail;
    },
  },
});

export const {
  setBridgeOverpassSlider,
  setBridgeUnderPassSlider,
  setBridgeOverWaterSlider,
  setBridgeOverLandSlider,
  setTunnelSlider,
  setRailSlider,
  toggleBridgeOverpassVisibility,
  toggleBridgeUnderPassVisibility,
  toggleBridgeOverWaterVisibility,
  toggleBridgeOverLandVisibility,
  toggleTunnelVisibility,
  toggleRailVisibility,
} = sliderSlice.actions;

export default sliderSlice.reducer;

// Selectors for slider values
export const getBridgeOverpassSlider = (state: any) => state.sliders.sliders.bridge_overpass;
export const getBridgeUnderPassSlider = (state: any) => state.sliders.sliders.bridge_underpass;
export const getBridgeOverWaterSlider = (state: any) => state.sliders.sliders.bridge_overwater;
export const getBridgeOverLandSlider = (state: any) => state.sliders.sliders.bridge_overland;
export const getTunnelSlider = (state: any) => state.sliders.sliders.tunnel;
export const getRailSlider = (state: any) => state.sliders.sliders.rail;

// Selectors for visibility
export const getBridgeOverpassVisibility = (state: any) => state.sliders.visibility.bridge_overpass;
export const getBridgeUnderPassVisibility = (state: any) => state.sliders.visibility.bridge_underpass;
export const getBridgeOverWaterVisibility = (state: any) => state.sliders.visibility.bridge_overwater;
export const getBridgeOverLandVisibility = (state: any) => state.sliders.visibility.bridge_overland;
export const getTunnelVisibility = (state: any) => state.sliders.visibility.tunnel;
export const getRailVisibility = (state: any) => state.sliders.visibility.rail;
