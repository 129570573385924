import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userRoutes: [
    // { id: 1, name: "Colorado" },
    // { id: 2, name: "Illinois" },
    // { id: 3, name: "Washington" },
    // { id: 4, name: "Ohio" },
    // { id: 5, name: "Massachusetts" },
  ],
};

const userRoutesSlice = createSlice({
  name: "userRoutes",
  initialState,
  reducers: {
    setUserRoutes: (state, action) => {
      try {
        state.userRoutes = action.payload;
      } catch (e) {
        alert("Failed to load user routes. Please try again.");
      }
    },
  },
});

export const { setUserRoutes } = userRoutesSlice.actions;

export default userRoutesSlice.reducer;

export const getUserRoutes = (state: any) => state.userRoutes.userRoutes;
