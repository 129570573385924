import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Map from "../map/Map";
import { RiskStackedBarChart } from "../charts/RiskStackedBarChart";
import { MultipleRiskLineChart } from "../charts/MultipleRiskLineChart";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { logout } from "../../auth";
import Button from "@material-ui/core/Button";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import RouteUpload from "../upload/RouteUpload";
import Tabs from "../tabs/Tabs";

import { useAppSelector } from "../../redux/hooks";
import { getChart } from "../../redux/reducers/chartDisplaySlice";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { getRoute } from "../../redux/reducers/uploadedRouteSlice";

const drawerWidth = 650;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      height: "100vh",
      overflow: "hidden",
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      height: 65,
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    bottomDrawer: {
      width: "100%",
      flexShrink: 0,
    },
    bottomDrawerPaper: {
      width: "100%",
    },
    bottomDrawerPaperSmaller: {
      marginLeft: drawerWidth,
    },
    content: {
      flexGrow: 1,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
      display: "flex",
      flexDirection: "column",
    },
    contentShiftLeftRight: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    logo_image: {
      maxWidth: 120,
    },
    toolBar: {
      position: "relative",
      zIndex: 1400,
    },
    mapContainer: {
      flex: 1,
      transition: "height 0.3s",
    },
  })
);

export default function PersistentDrawerLeft() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [bottomDrawerOpen, setBottomDrawerOpen] = React.useState(false);
  const [mounted, setMounted] = useState(false); // New state to track mount

  let chart = useAppSelector(getChart);
  let route = useAppSelector(getRoute);
  let displayedChart;

  if (chart === 0) {
    displayedChart = <MultipleRiskLineChart />;
  } else {
    displayedChart = <RiskStackedBarChart />;
  }

  const handleDrawerOpen = () => {
    setOpen(true);
    document.getElementById("resizeMapBtn")?.click();
  };

  const handleDrawerClose = () => {
    setOpen(false);
    document.getElementById("resizeMapBtn")?.click();
  };

  const handleBottomDrawerOpen = () => {
    const mapContainer = document.getElementById("map-container");
    // Check if the element exists before manipulating its style
    if (mapContainer) {
      mapContainer.style.height = "calc(100vh - 426px)";
    }
    setBottomDrawerOpen(true);
    document.getElementById("resizeMapBtn")!.click();
  };

  const handleBottomDrawerClose = () => {
    const mapContainer = document.getElementById("map-container");
    // Check if the element exists before manipulating its style
    if (mapContainer) {
      mapContainer.style.height = "calc(100vh - 64px)";
    }
    setBottomDrawerOpen(false);
    document.getElementById("resizeMapBtn")!.click();
  };

  const handleLogout = () => {
    fetch("./user/logout", {
      method: "get",
    }).then((token) => {
      fetch("./user/?slo", {
        method: "get",
      })
        .then((r) => r.json())
        .then((res) => {
          logout();
          window.location.href = res.url;
        });
    });
  };

  useEffect(() => {
    // This will run after the initial render
    setMounted(true);
  }, []);

  useEffect(() => {
    console.log("Current route:", route);
    if (mounted && route) {
      // Check if mounted --this makes sure the drawer stays closed on page load
      handleBottomDrawerOpen();
    }
  }, [route]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <DoubleArrowIcon />
          </IconButton>
          <img src="/sr_logo_white.png" className={classes.logo_image} />
          <Grid justify="space-between" container spacing={2}>
            <Grid item>
              <div>
                <RouteUpload />
              </div>
            </Grid>
            <Grid item>
              <div>
                <Button color="inherit" onClick={handleLogout}>
                  Logout
                </Button>
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Tabs />
        <Divider />
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShiftLeftRight]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          className={classes.mapContainer}
        >
          <Box pl={2}></Box>
          <Grid item xs={12}>
            <Box pt={0}>
              <Map />
              <IconButton
                color="inherit"
                aria-label="open bottom drawer"
                onClick={handleBottomDrawerOpen}
                edge="start"
                className={clsx(
                  classes.menuButton,
                  bottomDrawerOpen && classes.hide
                )}
                style={{
                  position: "fixed",
                  bottom: "24px",
                  right: "-8px",
                  borderRadius: "50%",
                  backgroundColor: "white",
                  padding: "8px",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                }}
              >
                <KeyboardArrowUpIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <Drawer
          className={classes.bottomDrawer}
          variant="persistent"
          anchor="bottom"
          open={bottomDrawerOpen}
          classes={{
            paper: open
              ? classes.bottomDrawerPaperSmaller
              : classes.bottomDrawerPaper,
          }}
          style={{ justifyContent: "flex-end" }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleBottomDrawerClose}>
              <KeyboardArrowDownIcon />
            </IconButton>
          </div>
          {displayedChart}
        </Drawer>
      </main>
    </div>
  );
}
